import React, { useState, useEffect } from 'react';
import { Input, Button, Card, Typography, Switch } from 'antd';
import { useNavigate, Link } from 'react-router-dom';
import { ArrowRightOutlined, ChromeOutlined, MessageOutlined } from '@ant-design/icons';
import './styles.css';

const { Text, Title } = Typography;

const SearchPage = () => {
  const [companies, setCompanies] = useState([]);
  const [sitesAnalyzed, setSitesAnalyzed] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const navigate = useNavigate();

  const logoSrc = isDarkMode ? 'logo2white.png' : 'logo2.png';

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const baseUrl = 'https://patient-bush-a521.delayel06.workers.dev';
        const headers = {
          'apikey': process.env.REACT_APP_API_KEY,
          'Accept': 'application/json',
        };

        const response = await fetch(`${baseUrl}/websites`, { headers });
        const data = await response.json();
        
        const formattedCompanies = data
          .filter(company => typeof company.website_name === 'string')
          .map(company => ({
            id: company.website_name,
            name: company.pretty_name,
            website: `https://${company.url}`,
            favicon: `https://www.google.com/s2/favicons?domain=${company.url}&sz=32`
          }));
        
        setCompanies(formattedCompanies);
        setSitesAnalyzed(data.length);
      } catch (error) {
        console.error('Error fetching companies:', error);
      }
    };

    fetchCompanies();
  }, []);

  useEffect(() => {
    if (searchTerm) {
      const results = companies.filter(company => {
        const searchLower = searchTerm.toLowerCase();
        const companyName = (company.name || '').toLowerCase();
        const companyWebsite = (company.website || '').toLowerCase();
        
        return companyName.includes(searchLower) || companyWebsite.includes(searchLower);
      });
      
      setFilteredCompanies(results.slice(0, 3));
    } else {
      setFilteredCompanies([]);
    }
  }, [searchTerm, companies]);

  const handleCompanyClick = (company) => {
    navigate(`/page/${company.id}`, { state: { company } });
  };

  const styles = {
    pageWrapper: {
      minHeight: '100vh',
      background: isDarkMode 
        ? 'linear-gradient(to right, #1a1a1a, #2d2d2d)'
        : 'linear-gradient(to right, #c4dcf5, #a1c4fd)',
      transition: 'background 0.3s ease',
    },
    contentContainer: {
      maxWidth: '1400px',
      margin: '0 auto',
      padding: '2rem 1rem',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '2rem',
    },
    logo: {
      width: 'clamp(200px, 25%, 400px)',
      marginTop: '2rem',
    },
    mainSlogan: {
      color: 'white',
      textAlign: 'center',
      fontSize: 'clamp(1.5rem, 4vw, 2.5rem)',
      fontWeight: 'bold',
      fontFamily: 'Poppins, sans-serif',
      maxWidth: '800px',
    },
    searchContainer: {
      width: '100%',
      maxWidth: '800px',
      position: 'relative',
      zIndex: 2,
    },
    searchInput: {
      width: '100%',
      height: '4rem',
      fontSize: '1.25rem',
      borderRadius: '9999px',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.3)',
      backgroundColor: 'white',
      transition: 'all 0.3s ease',
    },
    searchResults: {
      position: 'absolute',
      top: '100%',
      left: 0,
      right: 0,
      backgroundColor: isDarkMode ? '#333' : 'white',
      borderRadius: '0 0 1rem 1rem',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.3)',
      overflow: 'hidden',
    },
    videoContainer: {
      width: '100%',
      maxWidth: '800px',
      aspectRatio: '16/9',
      borderRadius: '1rem',
      overflow: 'hidden',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.3)',
    },
    featuresGrid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(240px, 1fr))',
      gap: '2rem',
      width: '100%',
      maxWidth: '1200px',
      padding: '2rem 0',
    },
    featureCard: {
      height: '100%',
      borderRadius: '1rem',
      background: isDarkMode ? '#1f1f1f' : 'white',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      transition: 'transform 0.3s ease',
      '&:hover': {
        transform: 'translateY(-5px)',
      },
    },
    buttonGroup: {
      display: 'flex',
      gap: '1rem',
      flexWrap: 'wrap',
      justifyContent: 'center',
      width: '100%',
      maxWidth: '800px',
    },
    button: {
      minWidth: '200px',
      height: '3.5rem',
      borderRadius: '9999px',
      fontSize: '1.1rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '0.5rem',
    },
    darkModeSwitch: {
      position: 'fixed',
      top: '1rem',
      right: '2rem',  // Changed from '1rem' to '2rem' to move it left
      transform: 'scale(1.5)',
      zIndex: 3,
    },
    evaluationGuide: {
      backgroundColor: 'white',
      padding: '2rem',
      borderRadius: '1rem',
      marginTop: '2rem',
      width: '100%',
      maxWidth: '800px',
      color: isDarkMode ? 'white' : 'black',
    },
  };

  return (
    <div style={styles.pageWrapper}>
      <Switch
        checked={isDarkMode}
        onChange={setIsDarkMode}
        checkedChildren="🌙"
        unCheckedChildren="☀️"
        style={styles.darkModeSwitch}
      />
      
      <div style={styles.contentContainer}>
        <img src={logoSrc} alt="Company Logo" style={styles.logo} />
        
        <h1 style={styles.mainSlogan}>
          Understand privacy policies at a glance!
        </h1>
        
        <Text style={{ color: isDarkMode ? 'white' : '#333', fontSize: '1.2rem', fontWeight: 'bold' }}>
          More than {sitesAnalyzed} sites analyzed!
        </Text>
        
        <div style={styles.searchContainer}>
          <Input
            placeholder="Search companies..."
            size="large"
            style={styles.searchInput}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          
          {filteredCompanies.length > 0 && (
            <div style={styles.searchResults}>
              {filteredCompanies.map(company => (
                <Card
                  key={company.id}
                  onClick={() => handleCompanyClick(company)}
                  style={{
                    cursor: 'pointer',
                    background: isDarkMode ? '#333' : 'white',
                    borderRadius: 0,
                    borderBottom: '1px solid rgba(0,0,0,0.1)',
                  }}
                  bodyStyle={{ padding: '0.75rem 1rem' }}
                >
                  <div style={{ display: 'flex', alignItems: 'center', gap: '0.75rem' }}>
                    <img
                      src={company.favicon}
                      alt=""
                      style={{ width: '16px', height: '16px' }}
                      onError={(e) => e.target.style.display = 'none'}
                    />
                    <span style={{ color: isDarkMode ? 'white' : 'black' }}>{company.name}</span>
                    <span style={{ marginLeft: 'auto', color: isDarkMode ? '#888' : '#666', fontSize: '0.9em' }}>
                      {company.website}
                    </span>
                  </div>
                </Card>
              ))}
            </div>
          )}
        </div>

        <div style={styles.videoContainer}>
          <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/XuPjPATXwbA"
            title="PoliSee - Stay in good terms!"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>

        <div style={styles.buttonGroup}>
          <Button
            type="primary"
            shape="round"
            size="large"
            style={styles.button}
            onClick={() => navigate('/companies')}
            icon={<ArrowRightOutlined />}
          >
            Explore Policies
          </Button>
          
          <Button
            type="default"
            shape="round"
            size="large"
            style={styles.button}
            href='https://chromewebstore.google.com/detail/polisee-stay-in-good-term/gbgioimofljipoffnogaclfhpgemookk'
            icon={<ChromeOutlined />}
          >
            Chrome Extension
          </Button>
          
          <Button
            type="default"
            shape="round"
            size="large"
            style={styles.button}
            onClick={() => navigate('/feedback')}
            icon={<MessageOutlined />}
          >
            Feedback
          </Button>
        </div>

        <div style={styles.featuresGrid}>
          {[
            { icon: "📝", title: "Clear Summary", description: "A clear summary of the essential points, without legal jargon." },
            { icon: "🎯", title: "Trust Score", description: "A trust score to assess the risks associated with a site." },
            { icon: "🌐", title: "Privacy Alternatives", description: "Alternatives that respect your privacy, if necessary." },
            { icon: "📊", title: "Privacy Evaluation", description: "We evaluate privacy policies based on criteria like data collection, usage, security, and user rights." }
          ].map((feature, index) => (
            <Card key={index} style={styles.featureCard}>
              <div style={{ fontSize: '2rem', textAlign: 'center', marginBottom: '1rem' }}>
                {feature.icon}
              </div>
              <Title level={4} style={{ textAlign: 'center', color: isDarkMode ? 'white' : 'black', margin: '0 0 1rem' }}>
                {feature.title}
              </Title>
              <Text style={{ display: 'block', textAlign: 'center', color: isDarkMode ? '#ddd' : '#666' }}>
                {feature.description}
              </Text>
            </Card>
          ))}
        </div>

        <div style={styles.evaluationGuide}>
          <Title level={2} style={{ color: 'black', textAlign: 'center' }}>
            Privacy Policy Evaluation Guide
          </Title>
          <Text style={{ color:'#666' }}>
            <p>We analyze the privacy policies of services and products to help you understand their level of transparency and data protection. Here are the criteria we evaluate and how we calculate the final score.</p>
            
            <h3>📌 Evaluation Criteria</h3>
            <h4>Clarity & Accessibility 📝</h4>
            <ul>
              <li>Is the language clear and easy to understand?</li>
              <li>Is the policy easily accessible?</li>
            </ul>

            <h4>Data Collection 📊</h4>
            <ul>
              <li>What types of data are collected (name, email, location, etc.)?</li>
              <li>Does the service collect more data than necessary?</li>
            </ul>

            <h4>Data Usage 🔄</h4>
            <ul>
              <li>How is the collected data used?</li>
              <li>Are there risks of misuse (marketing, resale)?</li>
            </ul>

            <h4>Sharing with Third Parties 🤝</h4>
            <ul>
              <li>With whom is the data shared (partners, advertisers, authorities)?</li>
              <li>Are there safeguards regarding data sharing?</li>
            </ul>

            <h4>User Rights 🛡</h4>
            <ul>
              <li>Can you easily modify, export, or delete your data?</li>
              <li>Does the policy comply with GDPR and other regulations?</li>
            </ul>

            <h4>Data Security 🔐</h4>
            <ul>
              <li>What measures are in place to protect your data?</li>
              <li>Has the service had data breaches in the past?</li>
            </ul>

            <h4>Data Retention ⏳</h4>
            <ul>
              <li>How long is the data stored?</li>
              <li>Is there a clear deletion policy?</li>
            </ul>

            

            <p style={{ textAlign: 'center', marginTop: '1rem' }}>We help you make informed choices to protect your privacy! 🛡🔍</p>
          </Text>
        </div>

        <footer style={{ marginTop: '2rem', textAlign: 'center', width: '100%' }}>
          <Link
            to="/privacy"
            style={{
              backgroundColor: isDarkMode ? 'orange' : '#1890ff',
              color: 'white',
              padding: '0.75rem 1.5rem',
              borderRadius: '0.5rem',
              textDecoration: 'none',
              transition: 'background-color 0.3s ease',
            }}
          >
            Privacy Policy
          </Link>
        </footer>
      </div>
    </div>
  );
};

export default SearchPage;